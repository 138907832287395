<template>
    <div id="SelectSuccess">
        <img style="width: 80px" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210830163723425.png" alt="">
        <p style="margin-top: 15px;color: #333;font-size: 20px;font-weight: bold">选择{{useCount}}件自用商品</p>
        <p style="color: #333;margin-top: 5px">订单编号：{{orderNum}}</p>
        <p style="font-size: 14px">温馨提示：请前往微信搜索<span>蜜蛋管理</span>小程序，</p>
        <p>登录蜜蛋管理获得您的专属<span>蜜蛋店铺</span>！</p>
        <p>您当前蔓薇订单含澳洲产地（跨境发）中国产地（国内发）共计：<span>{{totalCount}}件</span>，您已选择<span>{{useCount}}件</span>商品自用，剩余<span>{{notUseCount}}件</span></p>
        <p>已存入蔓薇库存。</p>
        <p>自用商品将分为澳洲仓库直邮/国内发货两种形式快递至您的收货地址，具体发货方式以您订单详情为准。库存商品您可</p>
        <p>在App蔓薇库存账户或蜜蛋管理小程序“蔓薇库存”处，再次</p>
        <p>选择自提商品。</p>
        <div class="button_container"
             style="width: 100%;justify-content: space-between;display: flex;align-items: center;margin-top: 200px;box-sizing: border-box;padding-left: 30px;padding-right: 30px">
            <div @click="goBackHome" class="back_btn">返回首页</div>
            <div @click="checkOrder">查看订单</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectSuccess",
        data() {
            return {
                useCount: '',
                notUseCount: '',
                totalCount: '',
                orderNum: '',
                orderId: ''
            }
        },
        created() {
            document.title = '取货成功'
            this.useCount = this.$route.params.useCount
            this.notUseCount = this.$route.params.notUseCount
            this.totalCount = this.$route.params.totalCount
            this.orderNum = this.$route.params.orderNum
            this.orderId = this.$route.params.orderId
        },
        methods: {
            //查看订单
            checkOrder() {
                window.location.href = this.userHost + `/rolorweb/html/recharge/orderDetails.html?orderId=${this.orderId}&type=1&brandId=4&tabId=0&index=0`
            },
            //返回首页
            goBackHome() {
                window.location.href = "/"
            }
        }
    }
</script>

<style lang="scss">
    #SelectSuccess {
        overflow: hidden;
        padding-top: 30px;
        color: #999;
        line-height: 22px;

        p {
            span {
                color: rgb(255, 152, 160);
            }
        }

        .button_container {
            div {
                width: 140px;
                height: 30px;
                border-radius: 500px;
                display: flex;
                line-height: 31px;
                justify-content: center;
                background-color: rgb(255,152,160);
                color: #fff;
            }
            .back_btn{
                box-sizing: border-box;
                border: 1px solid #ccc;
                background-color: #fff;
                color: #666;
            }
        }
    }
</style>